.word-list
{
    background-color: rgb(251, 253, 249);
    font-weight:bold;
    margin: 1em;
    border-radius: 1em;
    border-style: outset;
}

.work-space
{
    background-color: rgb(241, 238, 238);
    margin: 1em;
    border-radius: 1em;
}

.game-text
{
    margin: 1em;
}